import React from "react";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import metaData from "@data/metaData.json";
import { css } from "@emotion/react";
import HeroSection from "../../components/HeroSection";
import data from "@data/financialServices.json";
import colors from "@variables/colors";
import { mdLayout } from "@variables/layout";
import ServiceSection from "../../components/services/ServiceSection";

const FinancialServicesPage = () => {
  return (
    <Layout>
      <SEO {...metaData.financialServices}>
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org/",
            "@type": "FinancialService",
            "name": "Xramile",
            "address": "\"Ul. Grzybowska 12/14, lok. 22 Warszawa, Poland 00-132\"",
            "image": "https://xramile.com/logo.png",
            "telephone": "+905418058542",
            "url": "https://xramile.com/"
          }
        `}</script>
      </SEO>

      <HeroSection
        css={hero_section_style}
        description={data.heroSection.description}
        title={data.heroSection.title}
        imgAlt={data.heroSection.imageTitle}
        imgSrc={data.heroSection.image}
      />
      {data.services.map((service, index) => (
        <ServiceSection
          data={service}
          data-direction={index % 2 == 0 ? "row-reverse" : ""}
        />
      ))}
    </Layout>
  );
};

export default FinancialServicesPage;

const hero_section_style = css`
  background: ${colors.mintGreen[200]};
  .hero-section__row {
    flex-direction: row-reverse;
    @media ${mdLayout} {
      flex-direction: column-reverse;
    }
  }
  .hero-section__content {
    width: 60%;
  }
  .hero-section__description {
    max-width: 54ch;
  }
  .hero-section__image {
    width: 30%;
  }
`;
